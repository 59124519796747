import React, { useState } from "react";
import InputBox from "../components/InputBox";
import * as xlsx from "xlsx";
import { addNewDoc } from "../utils/firebase.utils";
import toast from "react-hot-toast";

export default function PayrollUploading() {
  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [payregister, setPayregister] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleConvert = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { raw: false });
        setPayregister(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const docObject = {
    date: formData?.date,
    payregister,
    status: "approved",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    await addNewDoc("payrollRuns", docObject);
    setIsProcessing(false);
    toast.success("Upload successfull");
    setFormData({});
    setPayregister([]);
  };

  return (
    <div>
      <form action="" className="space-y-4" onSubmit={handleSubmit}>
        <InputBox
          label={"Select run date"}
          name="date"
          placeholder={"Select date"}
          type={"date"}
          onChange={handleChange}
          value={formData.date}
        />

        <input type="file" onChange={handleConvert} />
        <button type="submit" className="blue-button" disabled={isProcessing}>
          {isProcessing ? "Submitting..." : "Submit"}
        </button>
      </form>
    </div>
  );
}
