import React, { useEffect, useState } from "react";
import InputBox from "../../components/InputBox";
import {
  addNewDoc,
  getDocDataSnap,
  setDocData,
} from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import Select from "react-select";

export default function PersonnelRequestForm({ handleAddmode, data }) {
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = useState(data || {});
  const [isProcessing, setIsProcessing] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState(formData.comments || []);
  const [status, setStatus] = useState(formData.status || "");
  const [disabled, setDisabled] = useState(data ? true : false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const fetchComments = async () => {
      await getDocDataSnap("personnelRequests", data.id, (data) => {
        setComments(data.comments || []);
        setFormData(data);
      });
    };

    if (data) {
      fetchComments();
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setDisabled(true);

    const docObject = {
      ...formData,
      requestedBy: currentUser.email,
      updateType: "personnel request submission",
      createdAt: new Date().toLocaleString(),
      status: "pending budget clearance",
    };

    if (editMode) {
      await setDocData("personnelRequests", data.id, docObject);
      toast.success("Request updated successfully");
      handleAddmode();
      handleSendEmailNotification();
      return;
    }

    await addNewDoc("personnelRequests", docObject);
    toast.success("PRF Submitted Successfully");
    handleAddmode();
    handleSendEmailNotification();
  };

  const handleSendEmailNotification = async () => {
    const docObject = {
      requestor: currentUser.email,
      recipientName: "Henrik",
      recipientEmail: ["henrik.uy@leechiu.com"],
      subject: "Personnel Request Submission - For Budget Clearance",
      message: `Good day! ${currentUser.displayName} submitted a Personnel Request Form that needs budget clearance.`,
      link: "https://myhr.leechiu.app",
    };

    const sendEmail = async () => {
      await fetch("https://hrms-emailerv1.vercel.app/api/notifications", {
        method: "POST",

        body: JSON.stringify(docObject),
      });
    };
    sendEmail();
  };

  const handleNewComment = (e) => {
    setNewComment(e.target.value);
  };

  const handleSubmitComment = async () => {
    if (newComment === "") {
      toast.error("Please enter comment");
      return;
    }

    setIsProcessing(true);
    const commentObject = {
      message: newComment,
      createdAt: new Date().toLocaleString(),
      createdBy: currentUser.displayName,
      createdByEmail: currentUser.email,
    };
    const docObject = {
      ...formData,
      comments: [...comments, commentObject],
    };
    await setDocData("personnelRequests", data.id, docObject);
    toast.success("Comment Added Successfully");
    setNewComment("");
    setIsProcessing(false);
  };

  const statusItems = [
    "approved",
    "rejected",
    "returned",
    "pending budget clearance",
  ];
  const statusOptions = statusItems.map((item) => {
    return {
      label: item,
      value: item,
    };
  });

  const handleSelectStatus = (selected) => {
    setFormData({ ...formData, status: selected.value });
  };

  const handleUpdateStatus = async () => {
    setIsProcessing(true);

    if (formData.status === "approved") {
      const docObject = {
        ...formData,
        updatedAt: new Date().toLocaleString(),
        updatedBy: currentUser.email,
        updatedName: currentUser.displayName,
        updateType: "update of status",
        approvedBy: currentUser.email,
        approvedName: currentUser.displayName,
        approvedAt: new Date().toLocaleString(),
      };
      await setDocData("personnelRequests", data.id, docObject);
      toast.success("Status successfully updated");
      setIsProcessing(false);
      setStatus(formData.status);
    } else {
      const docObject = {
        ...formData,
        updatedAt: new Date().toLocaleString(),
        updatedBy: currentUser.email,
        updatedName: currentUser.displayName,
        updateType: "update of status",
      };
      await setDocData("personnelRequests", data.id, docObject);
      toast.success("Status successfully updated");
      setIsProcessing(false);
      setStatus(formData.status);
    }
  };

  const handleEditMode = () => {
    setDisabled(!disabled);
    setEditMode(!editMode);

    if (editMode) {
      setFormData(data);
    }
  };

  return (
    <div className="page relative flex items-start gap-4 flex-col lg:flex-row">
      <button className="close-button" onClick={handleAddmode}>
        Close
      </button>
      <div className="mt-4 max-sm:w-full w-96">
        <div className="header">Personnel Request Form</div>
        {data && (
          <div>
            <div>Requested by: {data.requestedBy}</div>
            <div>Date Requested: {data.createdAt}</div>
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4 mt-4">
          <InputBox
            name="jobTitle"
            label="Job Title"
            placeholder={"Job Title"}
            onChange={handleChange}
            value={formData.jobTitle}
            required
            disabled={disabled}
          />

          <div className="mt-4">
            <div className="label"> Description</div>
            <textarea
              className="border p-2 rounded-lg border-gray-200 w-full"
              name="description"
              rows="6"
              id=""
              placeholder="Enter Description"
              onChange={handleChange}
              required
              value={formData.description}
              disabled={disabled}
            ></textarea>
          </div>

          <InputBox
            name="budgetedSalary"
            label="Budget Salary"
            type="number"
            placeholder={"Enter Budgeted Monthly Salary"}
            onChange={handleChange}
            value={formData.budgetedSalary}
            required
            disabled={disabled}
          />

          <InputBox
            name="startDate"
            label="Target Start Date"
            placeholder={"Enter Target Start Date"}
            onChange={handleChange}
            value={formData.startDate}
            required
            type="date"
            disabled={disabled}
          />

          <div>
            <button className="blue-button" disabled={disabled} type={"submit"}>
              Submit for Budget Clearance
            </button>
          </div>
        </form>

        {data ? (
          <div className="mt-4">
            <div className="header">Status</div>
            <div>{status}</div>

            <div className="mt-4">
              {currentUser.roles.includes("finance-head") && (
                <div>
                  <div className="header">Budget Clearance</div>
                  <div>
                    <Select
                      options={statusOptions}
                      placeholder="Select New Status"
                      value={statusOptions.find(
                        (item) => item.value === formData.status
                      )}
                      onChange={(selected) => handleSelectStatus(selected)}
                      disabled={isProcessing}
                    />
                  </div>
                  <div className="mt-2">
                    <button
                      className="blue-button"
                      disabled={isProcessing}
                      onClick={handleUpdateStatus}
                    >
                      {isProcessing ? "Updating" : "Update Status"}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : null}

        {formData.status === "returned" &&
          formData.requestedBy === currentUser.email && (
            <button className="blue-button" onClick={handleEditMode}>
              {disabled ? "Edit this request" : "Cancel Edits"}
            </button>
          )}
      </div>

      {data ? (
        <div className="w-full">
          <div className="header mt-4">Comments</div>
          <div>
            <textarea
              className="border border-gray-200  p-2 rounded-lg w-full"
              type="text"
              row={"4"}
              placeholder="Enter comments here"
              name="comment"
              label="Enter new comment"
              onChange={handleNewComment}
              value={newComment}
            />
            <button
              className="blue-button"
              onClick={handleSubmitComment}
              disabled={isProcessing}
            >
              Add Comment
            </button>
          </div>

          <div className="space-y-2 mt-4">
            {comments.map((comment, index) => (
              <div key={index} className="comment">
                <div className="">{comment.message}</div>
                <div>{comment.createdBy}</div>
                <div>{comment.createdAt}</div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}
