import React, { useEffect, useState } from "react";
import { queryAllDocsByFieldSnap } from "../../utils/firebase.utils";

export default function OnlineApplications({ data }) {
  const [applications, setApplications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchApplications = async () => {
      await queryAllDocsByFieldSnap(
        "jobApplications",
        "jobPostId",
        data.id,
        (data) => {
          setApplications(data);
          setIsLoading(false);
        }
      );
    };
    fetchApplications();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-2 mt-4">
      {applications.map((item, index) => (
        <div
          key={index}
          className="flex items-center gap-4  bg-gray-100 p-4 rounded-lg"
        >
          <div className="flex items-center gap-1 font-bold">
            <div>{item.firstName}</div>
            <div>{item.lastName}</div>
          </div>
          <div className="">{item.email}</div>
          <div className="">{item.dateSubmitted}</div>

          <div className="underline">
            <a href={item.CV} target="_blank">
              Download CV
            </a>
          </div>
        </div>
      ))}
    </div>
  );
}
