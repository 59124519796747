import React, { useEffect } from "react";
import InputBox from "../../components/InputBox";
import Button from "../../components/Button";
import { useState } from "react";
import { getAllDocsSnap, getDocDataSnap } from "../../utils/firebase.utils";
import { handleTaxComputation, totalDeductions, totalGrossPay } from "../../utils/PayrollUtils";

function Payslip({ payrollDetails, payrollId, editDisabled, updatePayroll }) {
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState(payrollDetails);
  const [isLoading, setIsLoading] = useState(true);
  const [netPay, setNetPay] = useState(0);
  const [applicableTaxRate, setApplicableTaxRate] = useState(0);
  const [prevPayroll, setPrevPayroll] = useState({});

  console.log(payrollDetails);

  useEffect(() => {
    const fetchPrevPayroll = async () => {
      await getAllDocsSnap("payrollRuns", (data) => {
        const prev = data.find((item) => item.date === "2025-01-10");
        setPrevPayroll(prev);
        console.log(prev);
      });
    };
    fetchPrevPayroll();
  }, []);

  const formatter = (amount) => {
    return parseFloat(amount).toLocaleString(undefined, {
      minimumFractionDigits: 2,
    });
  };

  useEffect(() => {
    setFormData(payrollDetails);
    setIsLoading(false);
  }, [payrollDetails]);

  useEffect(() => {
    const netSalary = (totalGrossPay(formData) - totalDeductions(formData)).toLocaleString(
      undefined,
      {
        minimumFractionDigits: 2,
      }
    );
    setNetPay(netSalary);
  }, [formData]);

  // const totalGrossPay = () => {
  //   const {
  //     halfMonth,
  //     commission,
  //     incentives,
  //     overtime,
  //     salaryAdjustment,
  //     lwop,
  //     leaveConversion,
  //     thirteenMonthPay,
  //     nonTaxIncentives,
  //     taxRefund,
  //     thirteenMonthPayTaxable,
  //   } = formData;
  //   return (
  //     parseFloat(halfMonth) +
  //     parseFloat(commission) +
  //     parseFloat(incentives) +
  //     parseFloat(nonTaxIncentives || 0) +
  //     parseFloat(taxRefund || 0) +
  //     parseFloat(leaveConversion || 0) +
  //     parseFloat(overtime) +
  //     parseFloat(thirteenMonthPay || 0) +
  //     parseFloat(formData.thirteenMonthPayTaxable || 0) +
  //     parseFloat(formData?.reimbursement || 0) +
  //     parseFloat(salaryAdjustment) -
  //     parseFloat(lwop)
  //   );
  // };

  // const totalDeductions = () => {
  //   const {
  //     SSS,
  //     Philhealth,
  //     HDMF,
  //     maxicare,
  //     hdmf2,
  //     sssloan,
  //     hdmfloan,
  //     advances,
  //     otherDeductions,
  //     withholdingTax,
  //   } = formData;
  //   return (
  //     parseFloat(SSS) +
  //     parseFloat(Philhealth) +
  //     parseFloat(HDMF) +
  //     parseFloat(maxicare) +
  //     parseFloat(hdmf2) +
  //     parseFloat(sssloan) +
  //     parseFloat(hdmfloan) +
  //     parseFloat(advances) +
  //     parseFloat(otherDeductions) +
  //     parseFloat(withholdingTax)
  //   );
  // };

  const handleEditMode = () => {
    setEditMode(!editMode);
    const data = {
      ...formData,
      netPay: netPay,
    };
    if (editMode) {
      updatePayroll(data);
    }
  };

  const handleSss = (basic) => {
    const limit = 29750;
    const baseline = 4250;
    const increment = 22.5;
    const multiple = 500;
    const baseSSS = 202.5;
    const minSSS = 180;
    const maxSSS = 1350;

    if (formData.status === "hold") {
      return 0;
    }

    if (basic === "") return 0;
    if (basic <= 0) return 0;

    if (basic >= limit) {
      return maxSSS / 2;
    } else if (basic < baseline) {
      return minSSS / 2;
    } else {
      const sss =
        baseSSS + Math.floor((basic - baseline) / multiple) * increment;
      return sss / 2;
    }
  };

  const handleNewSss = (basic) => {
    const limit = 34750;
    const baseline = 5250;
    const increment = 25;
    const multiple = 500;
    const baseSSS = 275.5;
    const minSSS = 250;
    const maxSSS = 1750;

    if (formData.status === "hold") {
      return 0;
    }

    if (basic === "") return 0;
    if (basic <= 0) return 0;

    if (basic >= limit) {
      return maxSSS / 2;
    } else if (basic < baseline) {
      return minSSS / 2;
    } else {
      const sss =
        baseSSS + Math.floor((basic - baseline) / multiple) * increment;
      return sss / 2;
    }
  };

  const handlePagibig = (basic) => {
    if (formData?.status === "ret") {
      return 0;
    }

    if (formData.status === "hold") {
      return 0;
    }
    if (basic === "") return 0;
    if (basic <= 0) return 0;

    if (basic <= 1500) {
      return (basic * 0.01) / 2;
    } else if (basic > 10000) {
      return 200 / 2;
    } else return (basic * 0.02) / 2;
  };

  const handlePhilhealth = (basic) => {
    if (basic === "") return 0;
    if (formData?.status === "ret") {
      return 0;
    }

    if (formData.status === "hold") {
      return 0;
    }

    if (basic <= 0) {
      return 0;
    } else if (basic <= 10000) {
      return 500 / 4;
    } else if (basic > 100000) {
      return 5000 / 4;
    } else return (basic * 0.05) / 4;
  };

  const handleThirteenMonth = (basic) => {
    let thirteenMonth = 0;
    if (basic === "") {
      return 0;
    } else {
      thirteenMonth = basic / 24;
      return thirteenMonth;
    }
  };

  const handleTaxOnCommission = (commission) => {
    return commission * 0.35;
  };

  const handleOldTax = (basic) => {
    // change this formula
    let taxableThirteenMonth = 0;
    let sss = handleNewSss(basic);
    let philhealth = handlePhilhealth(basic);
    let pagibig = handlePagibig(basic);
    let thirteenMonth = handleThirteenMonth(basic);
    let tax = 0;
    let otherIncome =
      parseFloat(formData.incentives) +
      parseFloat(formData.overtime) +
      parseFloat(formData.leaveConversion || "0") +
      parseFloat(formData.salaryAdjustment) -
      parseFloat(formData.lwop);

    // taxableThirteenMonth = Math.max(0, basic - 90000);
    taxableThirteenMonth = parseFloat(formData.thirteenMonthPayTaxable || 0);

    const annual =
      (parseFloat(formData.commission) +
        taxableThirteenMonth +
        otherIncome +
        basic / 2 -
        sss -
        philhealth -
        pagibig) *
      24;
    if (annual <= 250000) {
      tax = 0;
    } else if (annual <= 400000) {
      tax = (annual - 250000) * 0.15;
      setApplicableTaxRate(0.15);
    } else if (annual <= 800000) {
      tax = 22500 + (annual - 400000) * 0.2;
      setApplicableTaxRate(0.2);
    } else if (annual <= 2000000) {
      tax = 102500 + (annual - 800000) * 0.25;
      setApplicableTaxRate(0.25);
    } else if (annual <= 8000000) {
      tax = 402500 + (annual - 2000000) * 0.3;
      setApplicableTaxRate(0.3);
    } else if (annual > 8000000) {
      tax = 2202500 + (annual - 8000000) * 0.35;
      setApplicableTaxRate(0.35);
    }

    let taxOnThirteenMonth = 0;
    taxOnThirteenMonth = applicableTaxRate * taxableThirteenMonth;

    return tax / 24;
  };

  const handleTax = (payslipDetails) => {
    // change this formula

    console.log(payrollDetails);
    let taxableThirteenMonth = 0;
    let sss = payslipDetails.SSS;
    let philhealth = payslipDetails.Philhealth;
    let pagibig = payslipDetails.HDMF;
    let thirteenMonth = payslipDetails.thirteenMonth;
    let tax = 0;
    let otherIncome =
      parseFloat(payslipDetails.incentives) +
      parseFloat(payslipDetails.overtime) +
      parseFloat(payslipDetails.leaveConversion || "0") +
      parseFloat(payslipDetails.salaryAdjustment) -
      parseFloat(payslipDetails.lwop);

    // taxableThirteenMonth = Math.max(0, basic - 90000);
    taxableThirteenMonth = parseFloat(
      payslipDetails.thirteenMonthPayTaxable || 0
    );

    const annual =
      (parseFloat(payslipDetails.commission) +
        taxableThirteenMonth +
        otherIncome +
        payrollDetails.basicSalary / 2 -
        sss -
        philhealth -
        pagibig) *
      24;

    console.log(taxableThirteenMonth);
    console.log(otherIncome);
    console.log(payrollDetails.basicSalary);
    console.log(sss);
    console.log(philhealth);
    console.log(pagibig);

    if (annual <= 250000) {
      tax = 0;
    } else if (annual <= 400000) {
      tax = (annual - 250000) * 0.15;
      setApplicableTaxRate(0.15);
    } else if (annual <= 800000) {
      tax = 22500 + (annual - 400000) * 0.2;
      setApplicableTaxRate(0.2);
    } else if (annual <= 2000000) {
      tax = 102500 + (annual - 800000) * 0.25;
      setApplicableTaxRate(0.25);
    } else if (annual <= 8000000) {
      tax = 402500 + (annual - 2000000) * 0.3;
      setApplicableTaxRate(0.3);
    } else if (annual > 8000000) {
      tax = 2202500 + (annual - 8000000) * 0.35;
      setApplicableTaxRate(0.35);
    }

    console.log(annual);

    let taxOnThirteenMonth = 0;
    taxOnThirteenMonth = applicableTaxRate * taxableThirteenMonth;
    console.log(tax / 24);

    return tax / 24;
  };

  const adjustForNewSss = (basic, empId) => {
    const newSss = handleNewSss(basic);
    console.log(basic);

    console.log(empId);
    const oldSss = prevPayroll.payregister?.find(
      (item) => item.id === empId
    )?.SSS;

    if (oldSss === undefined) {
      return newSss;
    }

    const diff = newSss - parseFloat(oldSss);
    const adjustedSss = parseFloat(oldSss) + diff;
    console.log(newSss, oldSss, diff);
    return newSss + diff;
    return 0;
  };

  useEffect(() => {
    let basic = 0;
    let basicForTax = 0;
    if (
      formData.basicSalary == 0 ||
      formData.basicSalary == 4050 ||
      formData.basicSalary === ""
    ) {
      basic = 100000;
      basicForTax = 0;
    } else {
      basic = formData.basicSalary;
      basicForTax = formData.basicSalary;
    }

    setFormData({
      ...formData,
      halfMonth: (formData.basicSalary / 2).toFixed(2),
      SSS: adjustForNewSss(basic, payrollDetails.id)?.toFixed(2),
      Philhealth: handlePhilhealth(basic).toFixed(2),
      HDMF: parseFloat(handlePagibig(basic)).toFixed(2),
      withholdingTax: handleTaxComputation(formData).toFixed(2),
    });
  }, [
    formData.basicSalary,
    formData.commission,
    formData.incentives,
    formData.overtime,
    formData.salaryAdjustment,
    formData.leaveConversion,
    formData.lwop,
    formData.status,
    formData.thirteenMonthPay,
    formData.thirteenMonthPayTaxable,
  ]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  console.log(payrollDetails)

  return (
    <div className="p-4">
      <div className="text-left mb-4">
        {!editDisabled && (
          <Button label={editMode ? "Save" : "Edit"} onClick={handleEditMode} />
        )}
      </div>

      <div className="text-left mb-8">
        <div className="font-semibold">
          Selected Employee: {payrollDetails.displayName}
        </div>
        <div className="font-semibold">
          Basic Salary : {formatter(formData.basicSalary)}
        </div>
      </div>
      <div className="">
        <form className="flex items-start gap-2 grid-cols-2 h-[500px]">
          <div className="flex flex-col gap-2 w-1/2 border border-solid h-[500px] px-4 py-2">
            <div className=" font-semibold text-orange-600">Gross Pay</div>
            <div className="flex flex-col h-full flex-wrap gap-4 ">
              <InputBox
                label="Employee Status"
                name="status"
                onChange={handleChange}
                value={formData.status}
                disabled={!editMode}
                placeholder={"indicate ret if retired"}
              />

              <InputBox
                label="Basic Salary"
                name="basicSalary"
                onChange={handleChange}
                value={formData.basicSalary}
                disabled={!editMode}
              />

              <InputBox
                label="Basic Salary (Half Month)"
                name=""
                onChange={handleChange}
                value={formData.halfMonth}
                disabled={!editMode}
              />

              <InputBox
                label="13th Month Pay (Non-Taxable)"
                name="thirteenMonthPay"
                onChange={handleChange}
                placeholder={"13th month pay"}
                value={formData.thirteenMonthPay}
                disabled={!editMode}
              />

              <InputBox
                label="13th Month Pay (Taxable)"
                name="thirteenMonthPayTaxable"
                onChange={handleChange}
                placeholder={"13th month pay taxable portion"}
                value={formData.thirteenMonthPayTaxable}
                disabled={!editMode}
              />
              <InputBox
                label="Commission"
                value={formData.commission}
                onChange={handleChange}
                name="commission"
                disabled={!editMode}
              />
              <InputBox
                label="Incentives"
                value={formData.incentives}
                onChange={handleChange}
                name="incentives"
                disabled={!editMode}
              />
              <InputBox
                label="Leave Conversion"
                value={formData.leaveConversion}
                onChange={handleChange}
                name="leaveConversion"
                disabled={!editMode}
              />
              <InputBox
                label="Other non-taxable bonuses or incentives"
                value={formData.nonTaxIncentives}
                onChange={handleChange}
                name="nonTaxIncentives"
                disabled={!editMode}
              />
              <InputBox
                label="Tax Refund"
                value={formData.taxRefund}
                onChange={handleChange}
                name="taxRefund"
                disabled={!editMode}
              />

              <InputBox
                label="Overtime"
                value={formData.overtime}
                onChange={handleChange}
                name="overtime"
                disabled={!editMode}
              />
              <InputBox
                label="Salary Adjustment"
                value={formData.salaryAdjustment}
                onChange={handleChange}
                name="salaryAdjustment"
                disabled={!editMode}
              />
              <InputBox
                label="Leave Without Pay"
                value={formData.lwop}
                onChange={handleChange}
                name="lwop"
                disabled={!editMode}
              />
              <InputBox
                label="Reimbursements / Other Adjustments"
                value={formData.reimbursement}
                onChange={handleChange}
                name="reimbursement"
                disabled={!editMode}
              />
              <div className="text-left font-semibold ">
                Total Gross Pay:{" "}
                {totalGrossPay(formData).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2 w-1/2 border border-solid h-[500px] px-4 py-2">
            <div className="font-semibold text-orange-600">Deductions</div>

            <div className="flex flex-col gap-4 flex-wrap h-full">
              <InputBox
                label="SSS"
                value={formData.SSS}
                onChange={handleChange}
                name="SSS"
                disabled={true}
              />
              <InputBox
                label="Philhealth"
                value={formData.Philhealth}
                onChange={handleChange}
                name="Philhealth"
                disabled={true}
              />
              <InputBox
                label="Pag-Ibig"
                value={formData.HDMF}
                onChange={handleChange}
                name="HDMF"
                disabled={true}
              />
              <InputBox
                label="HMO"
                value={formData.maxicare}
                onChange={handleChange}
                name="maxicare"
                disabled={!editMode}
              />
              <InputBox
                label="Pag-Ibig 2"
                value={formData.hdmf2}
                onChange={handleChange}
                name="hdmf2"
                disabled={!editMode}
              />
              <InputBox
                label="SSS Loan"
                value={formData.sssloan}
                onChange={handleChange}
                name="sssloan"
                disabled={!editMode}
              />

              <InputBox
                label="Pag-Ibig Loan"
                value={formData.hdmfloan}
                onChange={handleChange}
                name="hdmfloan"
                disabled={!editMode}
              />
              <InputBox
                label="Advances"
                value={formData.advances}
                onChange={handleChange}
                name="advances"
                disabled={!editMode}
              />
              <InputBox
                label="Other deductions"
                value={formData.otherDeductions}
                onChange={handleChange}
                name="otherDeductions"
                disabled={!editMode}
              />
              <InputBox
                label="Withholding Tax "
                value={formData.withholdingTax}
                onChange={handleChange}
                name="withholdingTax"
                disabled={!editMode}
              />
              <div className="text-left font-semibold">
                Total Deductions:{" "}
                {totalDeductions(formData).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </div>
            </div>
          </div>
        </form>
        <div className="text-left font-semibold">
          Net Pay:{" "} {netPay}
          {/* {(totalGrossPay() - totalDeductions()).toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })} */}
        </div>
      </div>
    </div>
  );
}

export default Payslip;
