import React, { useState } from "react";
import InputBox from "../../components/InputBox";
import { addNewDoc, storage } from "../../utils/firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

export default function NewAnnouncementForm({ setAddMode }) {
  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const currentUser = useSelector((state) => state.userObject);

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `announcements/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    setIsProcessing(true);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData({ ...formData, poster: downloadURL });
          setIsProcessing(false);
        });
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const docObject = {
      ...formData,
      createdAt: new Date().toLocaleString(),
      updateType: "New Announcement",
      createdBy: currentUser.email,
      status: "Unpublished",
    };

    try {
      await addNewDoc("announcements", docObject);
      setIsProcessing(false);
      setAddMode(false);
      toast.success("Announcement created successfully");
    } catch (error) {
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="page relative">
      <div className="header">Create a new announcement</div>
      <button className="close-button" onClick={() => setAddMode(false)}>
        Cancel
      </button>

      <div className="lg:w-80 w-full mt-8">
        <form className="space-y-4" onSubmit={handleSubmit}>
          <InputBox
            label="Title"
            name="title"
            type="text"
            placeholder={"Enter title of announcement"}
            value={formData.title}
            required
            onChange={handleChange}
          />

          <div>
            <div className="font-bold text-gray-600">Description</div>
            <textarea
              className="w-full p-2 rounded-lg border"
              rows={6}
              label="Description"
              name="description"
              type="text"
              placeholder={"Enter description of announcement"}
              value={formData.description}
              required
              onChange={handleChange}
            />
          </div>

          <InputBox
            label="Date of Event"
            name="date"
            type="date"
            placeholder={"Enter date of event"}
            value={formData.date}
            required
            onChange={handleChange}
          />

          <div>
            <div className="font-bold text-gray-600">Upload Poster Photo</div>
            <input type="file" onChange={handleUpload} />
          </div>

          <button className="blue-button">
            {isProcessing ? "Saving..." : "Save"}
          </button>
        </form>
      </div>
    </div>
  );
}
