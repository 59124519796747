import React, { useEffect } from "react";
import { getAllDocsSnap } from "../../utils/firebase.utils";
import DownloadExcel from "../../components/DownloadExcel";

export default function Annualization() {
  const [payrollArray, setPayrollArray] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const fetchAllPayroll = async () => {
      await getAllDocsSnap("payrollRuns", (data) => {
        //filter only 2024 payroll
        data = data.filter((payroll) => {
          const year = payroll.date.split("-")[0];
          return (year === "2024") & (payroll.status === "approved");
        });
        setPayrollArray(data);
        setLoading(false);
      });
    };
    fetchAllPayroll();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const mappedPayrollArray = payrollArray.map((payroll) => {
    const data = payroll.payregister.map((register) => {
      return {
        ...register,
        date: payroll.date,
        payrollRunId: payroll.id,
      };
    });
    return data;
  });

  const flattenedPayrollArray = mappedPayrollArray.flat();

  //get all unique id from the flattened array
  const uniqueEmployeeIds = [
    ...new Set(flattenedPayrollArray.map((item) => item.id)),
  ];

  //create an array of ids with their corresponding data
  const employeeData = uniqueEmployeeIds.map((id) => {
    const data = flattenedPayrollArray.filter((item) => item.id === id);
    return data;
  });

  //get all unique object keys from flattened array
  const uniqueKeys = [
    ...new Set(flattenedPayrollArray.map((item) => Object.keys(item)).flat()),
  ];

  const tableHeaders = [
    "date",
    "payrollRunId",
    "employeeNumber",
    "firstName",
    "lastName",
    "displayName",
    "bankAccount",
    "id",
    "thirteenMonth",
    "basicSalary",
    "halfMonth",
    "overtime",
    "commission",
    "priorYearCommission",
    "leaveConversion",
    "thirteenMonthPayTaxable",
    "thirteenMonthPay",
    "nonTaxIncentives",
    "reimbursement",
    "lwop",
    "incentives",
    "salaryAdjustment",
    "SSS",
    "Philhealth",
    "HDMF",
    "maxicare",
    "hdmf2",
    "hdmfloan",
    "sssloan",
    "otherDeductions",
    "advances",
    "priorYearWithholding",
    "withholdingTax",
    "netPay",
    "status",
  ];

  const newEmployeeData = employeeData.map((data) => {
    const newEmployeeData = data.map((payslip) => {
      const newPayslip = {};
      tableHeaders.forEach((header) => {
        newPayslip[header] = payslip[header] || 0;
      });
      return newPayslip;
    });
    return newEmployeeData;
  });

  const flattenedEmployeeData = newEmployeeData.flat();

  return (
    <div>
      <div>
        <div className="header">Annualization</div>

        {/* map the data of first employee in employee data using the unique keys */}
        {/* <div>
          <table>
            <thead>
              <tr>
                {uniqueKeys.map((key, index) => (
                  <th>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {employeeData[1].map((data) => (
                <tr>
                  {uniqueKeys.map((key) => (
                    <td>{data[key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}
      </div>

      <DownloadExcel data={flattenedEmployeeData} label="Download" />
    </div>
  );
}
