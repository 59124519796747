import React from "react";

export default function EmployeeBreakdown({ breakdownPerDepartment }) {
  return (
    <div>
      <div className="header mt-8">Employee Count per Department </div>
      {breakdownPerDepartment.map((department, index) => {
        return (
          <div
            key={index}
            className="flex items-center justify-between p-2 border-b border-gray-200"
          >
            <div className="">{department.department}</div>
            <div className="">{department.total}</div>
          </div>
        );
      })}
      <div className="font-bold flex items-center justify-between gap-8">
        <div>Total Employees:</div>
        <div>
          {breakdownPerDepartment
            .map((data) => data.total)
            .reduce((a, b) => a + b, 0)}
        </div>
      </div>
    </div>
  );
}
