import { useState } from "react";

export const totalGrossPay = (payroll) => {
  const {
    halfMonth,
    commission,
    incentives,
    overtime,
    salaryAdjustment,
    lwop,
    leaveConversion,
    thirteenMonthPay,
    nonTaxIncentives,
    taxRefund,
    thirteenMonthPayTaxable,
  } = payroll;
  return (
    parseFloat(halfMonth) +
    parseFloat(commission) +
    parseFloat(incentives) +
    parseFloat(nonTaxIncentives || 0) +
    parseFloat(taxRefund || 0) +
    parseFloat(leaveConversion || 0) +
    parseFloat(overtime) +
    parseFloat(thirteenMonthPay || 0) +
    parseFloat(payroll.thirteenMonthPayTaxable || 0) +
    parseFloat(payroll?.reimbursement || 0) +
    parseFloat(salaryAdjustment) -
    parseFloat(lwop)
  );
};

export const totalDeductions = (payroll) => {
  const {
    SSS,
    Philhealth,
    HDMF,
    maxicare,
    hdmf2,
    sssloan,
    hdmfloan,
    advances,
    otherDeductions,
    withholdingTax,
  } = payroll;
  return (
    parseFloat(SSS) +
    parseFloat(Philhealth) +
    parseFloat(HDMF) +
    parseFloat(maxicare) +
    parseFloat(hdmf2) +
    parseFloat(sssloan) +
    parseFloat(hdmfloan) +
    parseFloat(advances) +
    parseFloat(otherDeductions) +
    parseFloat(withholdingTax)
  );
};

export const handleTaxComputation = (payslipDetails) => {
  let applicableTaxRate = 0;

  // change this formula

  let taxableThirteenMonth = 0;
  let sss = payslipDetails.SSS;
  let philhealth = payslipDetails.Philhealth;
  let pagibig = payslipDetails.HDMF;
  let thirteenMonth = payslipDetails.thirteenMonth;
  let tax = 0;
  let otherIncome =
    parseFloat(payslipDetails.incentives) +
    parseFloat(payslipDetails.overtime) +
    parseFloat(payslipDetails.leaveConversion || "0") +
    parseFloat(payslipDetails.salaryAdjustment) -
    parseFloat(payslipDetails.lwop);

  // taxableThirteenMonth = Math.max(0, basic - 90000);
  taxableThirteenMonth = parseFloat(
    payslipDetails.thirteenMonthPayTaxable || 0
  );

  const annual =
    (parseFloat(payslipDetails.commission) +
      taxableThirteenMonth +
      otherIncome +
      payslipDetails.basicSalary / 2 -
      sss -
      philhealth -
      pagibig) *
    24;


  console.log(taxableThirteenMonth);
  console.log(otherIncome);
  console.log(payslipDetails.basicSalary);
  console.log(sss);
  console.log(philhealth);
  console.log(pagibig);

  if (annual <= 250000) {
    tax = 0;
  } else if (annual <= 400000) {
    tax = (annual - 250000) * 0.15;
    applicableTaxRate = 0.15;
  } else if (annual <= 800000) {
    tax = 22500 + (annual - 400000) * 0.2;
    applicableTaxRate = 0.2;
  } else if (annual <= 2000000) {
    tax = 102500 + (annual - 800000) * 0.25;
    applicableTaxRate = 0.25;
  } else if (annual <= 8000000) {
    tax = 402500 + (annual - 2000000) * 0.3;
    applicableTaxRate = 0.3;
  } else if (annual > 8000000) {
    tax = 2202500 + (annual - 8000000) * 0.35;
    applicableTaxRate = 0.35;
  }

  console.log(annual);

  let taxOnThirteenMonth = 0;
  taxOnThirteenMonth = applicableTaxRate * taxableThirteenMonth;
  console.log(tax / 24);

  return tax / 24;
};
