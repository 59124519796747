import React, { useState } from "react";
import SelectBox from "../../components/SelectBox";
import { useSelector } from "react-redux";

export default function ScorecardDetails({ scoreCard, setViewMode }) {
  const [formData, setFormData] = useState({});
  const currentUser = useSelector((state) => state.userObject);
  const item = scoreCard;

  const approverOptions = currentUser.leader
    ? [{ label: currentUser.leader, value: currentUser.leader }]
    : [{ label: "No approver", value: "No approver set" }];

  const handleSelect = (item, name) => {
    setFormData({ ...formData, [name]: item.value });
  };

  return (
    <div className="page">
      <div className="header">Scorecard Details</div>
      <button className="close-button" onClick={() => setViewMode(false)}>
        Close
      </button>
      <div>Appraisal Date: {item.date}</div>
      <div>Status: {item.status}</div>

      <div className="mt-4 flex flex-col gap-4">
        <div className="w-full md:w-1/3">
          <SelectBox
            name="reviewer"
            label="Select your reviewer"
            options={approverOptions}
            handleSelect={(item) => handleSelect(item, "reviewer")}
          />

          <SelectBox
            name="appraisalTemplate"
            label="Select Appraisal Template"
            options={[
              { label: "Template 1", value: "Template 1" },
              { label: "Template 2", value: "Template 2" },
            ]}
            handleSelect={(item) => handleSelect(item, "appraisalTemplate")}
          />
        </div>
      </div>
    </div>
  );
}
