import React, { useEffect, useState } from "react";
import { getAllDocsSnap } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";

export default function DepartmentLeaves() {
  const currentUser = useSelector((state) => state.userObject);

  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [approvedLeaves, setApprovedLeaves] = useState([]);

  useEffect(() => {
    const fetchAllLeaves = async () => {
      await getAllDocsSnap("leaveRequests", (data) => {
        const approvedLeaves = data.filter(
          (leave) => leave.status === "Approved"
        );
        setApprovedLeaves(approvedLeaves);
      });
    };
    fetchAllLeaves();
  }, []);

  useEffect(() => {
    const fetchAllEmployees = async () => {
      await getAllDocsSnap("users", (data) => {
        const investmentSales = data.filter(
          (user) =>
            user.department === currentUser.department &&
            !user.roles.includes("restricted")
        );
        setEmployees(investmentSales);
        setIsLoading(false);
      });
    };
    fetchAllEmployees();
  }, []);

  if (isLoading) {
    <div>Loading...</div>;
  }

  const ISLeaves = approvedLeaves.filter((leave) =>
    employees.some((employee) => employee.email === leave.requestedBy)
  );

  return (
    <div>
      <div className="header">Departmental Leave Management</div>
      <div className="mt-4">
        <div className="flex flex-col items-start gap-2">
          {employees.map((employee, index) => (
            <div className="" key={index}>
              {employee.displayName}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
