import toast from "react-hot-toast";
import React, { useEffect } from "react";
import { useState } from "react";
import InputBox from "../../components/InputBox";
import { addNewDoc, queryAllDocsByFieldSnap } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import PageModal from "../../components/PageModal";
import ScorecardDetails from "./ScorecardDetails";

function Appraisal() {
  const currentUser = useSelector((state) => state.userObject);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [newScoreCardCreated, setNewScoreCardCreated] = useState(false);
  const [scoreCardDate, setScoreCardDate] = useState("");
  const [scoreCards, setScoreCards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewMode, setViewMode] = useState(false);
  const [selectedScoreCard, setSelectedScoreCard] = useState({});

  useEffect(() => {
    const fetchScoreCards = async () => {
      await queryAllDocsByFieldSnap(
        "scorecards",
        "userId",
        currentUser.uid,
        (data) => {
          setScoreCards(data);
          setIsLoading(false);
        }
      );
    };
    fetchScoreCards();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleCreateNewScoreCard = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleChange = (e) => {
    setScoreCardDate(e.target.value);
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    const docObject = {
      date: scoreCardDate,
      status: "draft",
      createdBy: currentUser.email,
      createdAt: new Date().toLocaleString(),
      userId: currentUser.uid,
    };

    await addNewDoc("scorecards", docObject);
    toast.success("New score card created successfully");
    setNewScoreCardCreated(true);
    setShowDatePicker(false);
  };

  const handleViewMode = (item) => {
    setViewMode(true);
    setSelectedScoreCard(item);
  };

  return (
    <div className="p-4 text-left">
      <div className="header">Performance Management</div>
      <button className="blue-button" onClick={handleCreateNewScoreCard}>
        Start a new Scorecard
      </button>

      <div className="mt-4">
        {showDatePicker && (
          <div className="p-4 rounded-lg bg-white shadow-md">
            <div className="font-bold text-gray-600">Select Scorecard Date</div>
            <InputBox
              type="date"
              name="scoreCardDate"
              onChange={handleChange}
              required
            />
            <button
              className="blue-button mt-2"
              disabled={scoreCardDate === "" ? true : false}
              onClick={handleCreate}
            >
              Create
            </button>
          </div>
        )}
      </div>

      {/* mapping of all the scorecards */}

      {scoreCards.map((item, index) => (
        <div
          className="card mt-2"
          key={index}
          onClick={() => handleViewMode(item)}
        >
          <div className="font-bold text-blue-900">
            Appraisal Date: {item.date}
          </div>
          <div>Status: {item.status}</div>
        </div>
      ))}

      {viewMode && (
        <PageModal>
          <ScorecardDetails
            scoreCard={selectedScoreCard}
            setViewMode={setViewMode}
          />
        </PageModal>
      )}
    </div>
  );
}

export default Appraisal;
