import React, { useEffect, useState } from "react";
import { getAllDocsSnap } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import {
  Day,
  Inject,
  Month,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  Week,
  WorkWeek,
} from "@syncfusion/ej2-react-schedule";
import { registerLicense } from "@syncfusion/ej2-base";
import { Description } from "@mui/icons-material";

registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NMaF5cXmBCfEx0WmFZfVtgc19DYVZQQ2YuP1ZhSXxWdkRhX39ZdXVUQWBVWUY="
);

export default function DepartmentLeaves() {
  const currentUser = useSelector((state) => state.userObject);

  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [approvedLeaves, setApprovedLeaves] = useState([]);

  useEffect(() => {
    const fetchAllLeaves = async () => {
      await getAllDocsSnap("leaveRequests", (data) => {
        const approvedLeaves = data.filter(
          (leave) => leave.status === "Approved"
        );
        setApprovedLeaves(approvedLeaves);
        console.log(approvedLeaves);
      });
    };
    fetchAllLeaves();
  }, []);

  useEffect(() => {
    const fetchAllEmployees = async () => {
      await getAllDocsSnap("users", (data) => {
        const investmentSales = data.filter(
          (user) =>
            user.department === currentUser.department &&
            !user.roles.includes("restricted")
        );
        setEmployees(investmentSales);
        setIsLoading(false);
      });
    };
    fetchAllEmployees();
  }, []);

  if (isLoading) {
    <div>Loading...</div>;
  }

  const ISLeaves = approvedLeaves.filter((leave) =>
    employees.some((employee) => employee.email === leave.requestedBy)
  );

  console.log(approvedLeaves);

  const categoryColors = [
    {
      type: "Vacation Leave",
      color: "#4635B1",
    },
    {
      type: "Sick Leave",
      color: "#FF6347",
    },
    {
      type: "Maternity Leave",
      color: "#FF69B4",
    },
    {
      type: "Paternity Leave",
      color: "#4682B4",
    },
    {
      type: "Pending Approval ",
      color: "#FFA500",
    },
    {
      type: "Study Leave",
      color: "#008000",
    },
    {
      type: "Unpaid Leave",
      color: "#FF0000",
    },
  ];

  const data = ISLeaves.map((leave) => {
    return {
      id: leave.id,
      Description: leave.leaveType + " - " + leave.reason,
      isReadOnly: true,
      Subject: `${leave.requestedBy
        .split("@")[0]
        .split(".")
        .join(" ")
        .toUpperCase()} - ${leave.leaveType}`,
      StartTime: new Date(leave.startDate),
      EndTime: new Date(leave.endDate),
      IsAllDay: true,
      CategoryColor: categoryColors.find(
        (color) => color.type === leave.leaveType
      ).color,
    };
  });

  const onEventRendered = (args) => {
    const categoryColor = args.data.CategoryColor;
    args.element.style.backgroundColor = categoryColor;
  };

  const onPopupOpen = (args) => {
    if (
      (args.target &&
        !args.target.classList.contains("e-appointment") &&
        args.type === "QuickInfo") ||
      args.type === "Editor"
    ) {
      args.cancel = true;
    }
  };

  console.log(data);

  return (
    <div>
      <div className="header">Departmental Leave Management</div>
      <div className="mt-4 flex items-center justify-center">
        <ScheduleComponent
          eventSettings={{ dataSource: data }}
          eventRendered={onEventRendered}
          width={1080}
          height={550}
          currentView="Month"
          rowAutoHeight={true}
          //read only
        >
          <ViewsDirective>
            {/* <ViewDirective option="Day" /> */}
            <ViewDirective option="WorkWeek" />
            <ViewDirective option="Month" />
          </ViewsDirective>
          <Inject services={[WorkWeek, Month]} />
        </ScheduleComponent>
      </div>
    </div>
  );
}
