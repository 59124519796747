import React from "react";
import { Link, NavLink } from "react-router-dom";

import { useSelector } from "react-redux";
import { MdDashboard } from "react-icons/md";
import { FaPeopleRoof } from "react-icons/fa6";
import { GrDocumentPerformance } from "react-icons/gr";
import { IoAnalytics } from "react-icons/io5";
import { FaSuitcaseRolling } from "react-icons/fa";
import { FaMoneyCheck } from "react-icons/fa6";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { IoDocumentTextSharp } from "react-icons/io5";
import { MdMeetingRoom } from "react-icons/md";
import { TbReportMoney } from "react-icons/tb";
import { MdModelTraining } from "react-icons/md";
import { MdOutlineContactEmergency } from "react-icons/md";
import { TfiAnnouncement } from "react-icons/tfi";
import { SiOpenaccess } from "react-icons/si";
import { FaPeopleGroup } from "react-icons/fa6";
import { MdOutlineSettingsAccessibility } from "react-icons/md";

function Sidebar() {
  const currentUser = useSelector((state) => state.userObject);

  const sidebarMenu = [
    {
      title: "Dashboard",
      icon: <MdDashboard />,
      link: "/dashboard",
      accessRoles: ["admin", "user"],
    },
    {
      title: "Employee Management",
      icon: <FaPeopleRoof />,
      link: "/employees",
      accessRoles: ["admin", "approver", "maker"],
    },
    {
      title: "Departmental Leaves Management",
      icon: <FaPeopleRoof />,
      link: "/leaves-mgt",
      accessRoles: ["department approver", "super-admin"],
    },
    {
      title: "Performance Management",
      icon: <GrDocumentPerformance />,
      link: "/appraisal",
      accessRoles: ["user"],
    },
    {
      title: "Appraisal Templates",
      icon: <MdOutlineSettingsAccessibility />,
      link: "/appraisal-templates",
      accessRoles: ["hr approver", "hr maker"],
    },
    {
      title: "Data and Analytics",
      icon: <IoAnalytics />,
      link: "/analytics",
      accessRoles: ["admin", "approver"],
    },
    {
      title: "Leaves Management",
      icon: <FaSuitcaseRolling />,
      link: "/leaves",
      accessRoles: ["user"],
    },
    {
      title: "Compensation and Benefits",
      icon: <FaMoneyCheck />,
      link: "/compensation",
      accessRoles: ["user", "super-admin"],
    },
    {
      title: "Payroll",
      icon: <FaRegMoneyBillAlt />,
      link: "/payroll",
      accessRoles: ["admin", "approver", "maker"],
    },
    {
      title: "Recruitment",
      icon: <FaPeopleGroup />,
      link: "/recruitment",
      accessRoles: ["hr maker", "hr approver"],
    },
    {
      title: "Personnel Request",
      icon: <FaPeopleGroup />,
      link: "/personnelRequest",
      accessRoles: ["department approver", "approver"],
    },
    {
      title: "Send HR Requests",
      icon: <IoDocumentTextSharp />,
      link: "/requests",
      accessRoles: ["user"],
    },
    {
      title: "HR Requests",
      icon: <IoDocumentTextSharp />,
      link: "/hr-requests",
      accessRoles: ["hr approver", "hr maker"],
    },
    {
      title: "Meeting Rooms",
      icon: <MdMeetingRoom />,
      link: "/meetingRooms",
      accessRoles: ["user"],
    },

    {
      title: "BIR Reporting",
      icon: <TbReportMoney />,
      link: "/bir",
      accessRoles: ["admin", "approver"],
    },
    {
      title: "Trainings",
      icon: <MdModelTraining />,
      link: "/trainings",
      accessRoles: ["admin", "approver"],
    },
    {
      title: "Employee Trainings",
      icon: <MdModelTraining />,
      link: "/training",
      accessRoles: ["user"],
    },
    {
      title: "Call Tree",
      icon: <MdOutlineContactEmergency />,
      link: "/calltree",
      accessRoles: ["admin", "approver"],
    },
    {
      title: "Announcements",
      icon: <TfiAnnouncement />,
      link: "/announcements",
      accessRoles: ["admin", "approver", "hr-maker", "marketing"],
    },
    {
      title: "Access Management",
      icon: <SiOpenaccess />,
      link: "/userAccess",
      accessRoles: ["admin"],
    },
    {
      title: "Approver Inbox",
      icon: <SiOpenaccess />,
      link: "/approverInbox",
      accessRoles: ["department approver", "approver", "finance-head"],
    },
  ];

  return (
    <div className="text-gray-600">
      {sidebarMenu.map((item, index) => (
        <div key={index} className="sidebar flex items-center text-xs bg-white">
          {currentUser.roles.some((role) => item.accessRoles.includes(role)) ? (
            <NavLink
              to={item.link}
              className=" flex items-center gap-2 text-xs px-2 py-2 w-full text-left hover:bg-leechiuOrange hover:text-white hover:border-r-4 border-leechiuBlue"
            >
              <div className="pl-4">{item.icon}</div>
              {item.title}
            </NavLink>
          ) : null}
        </div>
      ))}
    </div>
  );
}

export default Sidebar;
