import React, { useState } from "react";
import InputBox from "../../components/InputBox";

export default function NewTemplateForm({ setAddMode }) {
  const [specificObjectives, setSpecificObjectives] = useState([]);
  const [softSkills1, setSoftSkills1] = useState([]);
  const [softSkills2, setSoftSkills2] = useState([]);

  const objectiveFormat = {
    category: "",
    kpi: "",
    weight: "",
  };

  const handleChange = (e, objective, index) => {
    const { name, value } = e.target;
    const updatedObjectives = specificObjectives.map((item, i) => {
      if (i === index) {
        return { ...item, [name]: value };
      }
      return item;
    });
    setSpecificObjectives(updatedObjectives);
  };

  return (
    <div className="page">
      <div className="text-sm font-bold text-leechiuBlue">
        New Appraisal Template Form
      </div>

      <div className="mt-4">
        Add Individual Objectives below for each Category:
      </div>

      <div className="border border-gray-100 mb-4">
        <div className="header mb-4 bg-leechiuOrange bg-opacity-50 p-2">
          Specific Objectives
        </div>
        <button className="close-button" onClick={() => setAddMode(false)}>
          Close
        </button>
        <button
          className="orange-button mb-4 mx-2"
          onClick={() =>
            setSpecificObjectives([...specificObjectives, objectiveFormat])
          }
        >
          Add Objective
        </button>
        <div className="p-2">
          {specificObjectives.map((objective, index) => (
            <div
              key={index}
              className="w-full space-y-2 p-2 bg-gray-200 mt-2 rounded-lg"
            >
              <InputBox
                label="Category"
                name="category"
                type="text"
                placeholder="Category"
                onChange={(e) => handleChange(e, objective, index)}
              />
              <div>
                <label htmlFor="" className="text-blue-900 font-bold">
                  KPI
                </label>
                <textarea
                  className="p-2 rounded-lg border border-gray-200 w-full"
                  row="4"
                  label="KPI"
                  name="kpi"
                  type="text"
                  placeholder="KPI"
                />
              </div>
              <InputBox
                label="Weight"
                name="weight"
                type="text"
                placeholder="Weight"
              />
              <button
                onClick={() => {
                  const updatedObjectives = specificObjectives.filter(
                    (item, i) => i !== index
                  );
                  setSpecificObjectives(updatedObjectives);
                }}
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="border border-gray-100 mb-4">
        <div className="header mb-4 bg-leechiuOrange bg-opacity-50 p-2">
          Soft Skills 1
        </div>
        <button className="close-button" onClick={() => setAddMode(false)}>
          Close
        </button>
        <button
          className="orange-button mb-4 mx-2"
          onClick={() => setSoftSkills1([...softSkills1, objectiveFormat])}
        >
          Add Objective
        </button>
        {softSkills1.map((objective, index) => (
          <div
            key={index}
            className="w-full space-y-2 p-2 bg-gray-200 mt-2 rounded-lg"
          >
            <InputBox
              label="Category"
              name="category"
              type="text"
              placeholder="Category"
              onChange={(e) => handleChange(e, objective, index)}
            />
            <textarea
              className="p-2 rounded-lg border border-gray-200 w-full"
              row="4"
              label="KPI"
              name="kpi"
              type="text"
              placeholder="KPI"
            />
            <InputBox
              label="Weight"
              name="weight"
              type="text"
              placeholder="Weight"
            />
            <button
              onClick={() => {
                const updatedObjectives = softSkills1.filter(
                  (item, i) => i !== index
                );
                setSoftSkills1(updatedObjectives);
              }}
            >
              Delete
            </button>
          </div>
        ))}
      </div>

      <div>
        <div>Save your changes by clicking button below</div>
        <button className="blue-button">Save</button>
      </div>
    </div>
  );
}
