import React, { useEffect, useState } from "react";

import { getAllDocsSnap, setDocData } from "../utils/firebase.utils";
import SelectBox from "../components/SelectBox";
import Select from "react-select";
import toast from "react-hot-toast";

export default function GenderAssignment() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      await getAllDocsSnap("employees", (data) => {
        setUsers(data);
        setIsLoading(false);
      });
    };
    fetchUsers();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleSearch = (e, userId) => {
    setSearchInput(e.target.value);
    setSelectedUser(userId);
  };

  const searchResults = users
    .filter((user) =>
      user.displayName.toLowerCase().includes(searchInput.toLowerCase())
    )
    .slice(0, 20);

  const departmentFilters = new Set(users.map((user) => user.department));
  const departmentOptions = Array.from(departmentFilters).map((department) => ({
    value: department,
    label: department,
  }));

  const handleSelectFilter = (selected) => {
    setActiveFilter(selected.value);
  };

  const currentList = activeFilter
    ? users.filter((user) => user.department === activeFilter)
    : users;

  const handleUpdate = async (user) => {
    const docObject = {
      ...user,
      gender: searchInput,
    };
    await setDocData("employees", user.id, docObject);
    toast.success("Gender updated successfully");
  };

  return (
    <div>
      <div className="header">List of All User and Corresponding Approvers</div>

      <div>
        <SelectBox
          options={departmentOptions}
          placeholder="Filter by Department"
          handleSelect={(selected) => handleSelectFilter(selected)}
        />
      </div>

      <div className="mt-8">
        {currentList.map((user, index) => (
          <div
            key={index}
            className="card mt-2 flex items-center gap-4 relative"
          >
            <div>{user.displayName}</div>
            <div>{user.id}</div>
            <div>{user.department}</div>
            <div>
              <input
                type="text"
                name="gender"
                className="border p-2 rounded-lg relative"
                placeholder="Gender"
                onChange={(e) => handleSearch(e, user.id)}
              />

              {/* {searchInput.length > 0 && user.id === selectedUser && (
                <div className="absolute z-20 rounded-lg border shadow-sm h-60 w-80 bg-white">
                  {searchResults.map((result, index) => (
                    <div key={index} className="p-2 hover:bg-gray-100">
                      {result.displayName}
                    </div>
                  ))}
                </div>
              )} */}
            </div>
            <button className="blue-button" onClick={() => handleUpdate(user)}>
              Update
            </button>

            <div>{user.gender}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
