import React, { useEffect, useState } from "react";
import PageModal from "../../components/PageModal";
import NewTemplateForm from "./NewTemplateForm";
import { getAllDocsSnap } from "../../utils/firebase.utils";

export default function AppraisalTemplates() {
  const [addMode, setAddMode] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});

  useEffect(() => {
    // fetch all appraisal templates
    const fetchTemplates = async () => {
      await getAllDocsSnap("appraisalTemplates", (data) => {
        setTemplates(data);
        setIsLoading(false);
      });
    };
    fetchTemplates();
  }, []);

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  return (
    <div>
      <div className="header">Appraisal Templates</div>
      <button className="blue-button" onClick={handleAddMode}>
        Create a new template
      </button>

      <div className="mt-4 mb-2">Click a template below to edit</div>
      {
        // show all templates

        templates.map((template) => (
          <div
            key={template.id}
            className="template-card card mt-2"
            onClick={() => {
              setEditMode(true);
              setSelectedTemplate(template);
            }}
          >
            <div>{template.name}</div>
            <div>{template.department}</div>
            <div>{template.createdAt}</div>
          </div>
        ))
      }

      {addMode && (
        <PageModal>
          <NewTemplateForm setAddMode={setAddMode} />
        </PageModal>
      )}

    
    </div>
  );
}
