import React from "react";
import PieChartComp from "../../charts/PieChart";

export default function EmployeeDemographics({ employees }) {
  //sort employees by name
  employees.sort((a, b) => {
    if (a.lastName < b.lastName) {
      return -1;
    }
    if (a.lastName > b.lastName) {
      return 1;
    }
    return 0;
  });

  const maleEmployees = employees.filter(
    (employee) => employee.gender === "Male"
  );

  const femaleEmployees = employees.filter(
    (employee) => employee.gender === "Female"
  );

  const withoutGender = employees.filter(
    (employee) => employee.gender === undefined
  );

  const unknownGender = employees.filter(
    (employee) => employee.gender !== "Male" && employee.gender !== "Female"
  );

  const employeeData = [
    { name: "Male", value: maleEmployees.length },
    { name: "Female", value: femaleEmployees.length },
  ];

  return (
    <div>
      <div className="header">Employee Demographics</div>
      <div className="w-[500px] h-[500px] bg-gray-300">
        <PieChartComp dataInput={employeeData} />
      </div>
    </div>
  );
}
