import React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getDocDataSnap,
  setDocData,
  deleteDocById,
  getAllDocsSnap,
  addNewDoc,
} from "../../utils/firebase.utils";
import PayrollDetails from "./PayrollDetails";
import InputBox from "../../components/InputBox";
import Payslip from "./Payslip";
import DownloadExcel from "../../components/DownloadExcel";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import WhiteButton from "../../components/WhiteButton";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  handleTaxComputation,
  totalDeductions,
  totalGrossPay,
} from "../../utils/PayrollUtils";

function DraftPayroll() {
  const { id } = useParams();
  const [payroll, setPayroll] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [employeePayrollDetails, setEmployeePayrollDetails] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totalNetPay, setTotalNetPay] = useState(0);
  const [lastPayroll, setLastPayroll] = useState({});
  const [newDate, setNewDate] = useState("");

  const currentUser = useSelector((state) => state.userObject);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLastPayroll = async () => {
      const data = await getAllDocsSnap("payrollRuns", (data) => {
        const lastPayReg = data.find((item) => item.date === "2025-01-10");
        console.log(lastPayReg);
        setLastPayroll(lastPayReg.payregister);
      });
    };
    fetchLastPayroll();
  }, []);

  useEffect(() => {
    const fetchPayroll = async () => {
      const data = await getDocDataSnap("payrollRuns", id, (data) => {
        setPayroll(data);
        setFilteredData(data.payregister);
        setIsLoading(false);
      });
    };
    fetchPayroll();
  }, []);

  useEffect(() => {
    if (selectedEmployee.length > 0) {
      const data = payroll.payregister.filter(
        (item) => item.id === selectedEmployee[0]
      );
      setEmployeePayrollDetails(data[0]);
    }
  }, [selectedEmployee]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const formatter = (amount) => {
    return parseFloat(amount).toLocaleString(undefined, {
      minimumFractionDigits: 2,
    });
  };

  const updatePayrollDetails = (e) => {
    setEmployeePayrollDetails({
      ...employeePayrollDetails,
      [e.target.name]: e.target.value,
    });
  };

  const updatePayroll = async (employeeData) => {
    const data = {
      ...payroll,
      payregister: payroll.payregister.map((item) => {
        if (item.id === selectedEmployee[0]) {
          return employeeData;
        }
        return item;
      }),
    };
    try {
      await setDocData("payrollRuns", id, data).then(() => {
        toast.success("Employee Payroll Updated");
      });
    } catch (error) {}

    setPayroll(data);
  };

  const handleSubmit = async () => {
    if (window.confirm("Are you sure to submit this payroll for approval? ")) {
      const data = {
        ...payroll,
        status: "submitted",
      };
      try {
        await setDocData("payrollRuns", id, data).then(() => {
          toast.success("Payroll Finalized and Submitted for Approval");
          navigate("/payroll");
        });
      } catch (error) {}
      setPayroll(data);
    } else {
      return;
    }
  };

  const handleDeleteDraft = async () => {
    if (window.confirm("Are you sure to delete this payroll draft?")) {
      try {
        navigate("/payroll");
        await deleteDocById("payrollRuns", id).then(() => {});
        toast.success("Payroll Draft Deleted");
      } catch (error) {}
    } else {
      return;
    }
  };

  const handleSearch = (e) => {
    const data = payroll.payregister.filter((item) =>
      item.displayName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredData(data);
  };

  const handleDeleteItem = async () => {
    if (selectedEmployee.length < 1) {
      return;
    }
    if (
      window.confirm(
        "Are you sure to delete this item? Take note that deleting is irreversible."
      )
    ) {
      const data = {
        ...payroll,
        payregister: payroll.payregister.filter(
          (item) => item.id !== selectedEmployee[0]
        ),
      };
      try {
        await setDocData("payrollRuns", id, data).then(() => {
          toast.success("Item Deleted");
        });
      } catch (error) {}
      setPayroll(data);
      setSelectedEmployee([]);
    } else {
      return;
    }
  };

  const handleSss = (basic) => {
    const limit = 29750;
    const baseline = 4250;
    const increment = 22.5;
    const multiple = 500;
    const baseSSS = 202.5;
    const minSSS = 180;
    const maxSSS = 1350;

    if (basic === "") return 0;
    if (basic <= 0) return 0;

    if (basic >= limit) {
      return maxSSS / 2;
    } else if (basic < baseline) {
      return minSSS / 2;
    } else {
      const sss =
        baseSSS + Math.floor((basic - baseline) / multiple) * increment;
      return sss / 2;
    }
  };

  const handlePagibig = (basic) => {
    if (basic === "") return 0;
    if (basic <= 0) return 0;

    if (basic <= 1500) {
      return (basic * 0.02) / 2;
    } else if (basic > 5000) {
      return 200 / 2;
    }
  };

  const handlePhilhealth = (basic) => {
    if (basic === "") return 0;
    if (basic <= 0) {
      return 0;
    } else if (basic <= 10000) {
      return 500 / 4;
    } else if (basic > 100000) {
      return 5000 / 4;
    } else return (basic * 0.05) / 4;
  };

  const handleThirteenMonth = (basic) => {
    let thirteenMonth = 0;
    if (basic === "") {
      return 0;
    } else {
      thirteenMonth = basic / 24;
      return thirteenMonth;
    }
  };

  const handleTax = (basic) => {
    // change this formula
    let taxableThirteenMonth = 0;
    let sss = handleNewSss(basic);
    let philhealth = handlePhilhealth(basic);
    let pagibig = handlePagibig(basic);
    let thirteenMonth = handleThirteenMonth(basic);
    let tax = 0;

    taxableThirteenMonth = Math.max(0, thirteenMonth * 24 - 90000);

    const annual =
      (basic / 2 - sss - philhealth - pagibig) * 24 + taxableThirteenMonth;
    if (annual <= 250000) {
      tax = 0;
    } else if (annual <= 400000) {
      tax = (annual - 250000) * 0.15;
    } else if (annual <= 800000) {
      tax = 22500 + (annual - 400000) * 0.2;
    } else if (annual <= 2000000) {
      tax = 102500 + (annual - 800000) * 0.25;
    } else if (annual <= 8000000) {
      tax = 402500 + (annual - 2000000) * 0.3;
    } else if (annual > 8000000) {
      tax = 2202500 + (annual - 8000000) * 0.35;
    }
    return tax / 24;
  };

  const handleNewSss = (basic) => {
    const limit = 34750;
    const baseline = 5250;
    const increment = 25;
    const multiple = 500;
    const baseSSS = 275.5;
    const minSSS = 250;
    const maxSSS = 1750;

    if (basic === "") return 0;
    if (basic <= 0) return 0;

    if (basic >= limit) {
      return maxSSS / 2;
    } else if (basic < baseline) {
      return minSSS / 2;
    } else {
      const sss =
        baseSSS + Math.floor((basic - baseline) / multiple) * increment;
      return sss / 2;
    }
  };

  const adjustForNewSss = (basic, empId) => {
    const newSss = handleNewSss(basic);
    const oldSss = lastPayroll.find((item) => item.id === empId)?.SSS;

    if (oldSss === undefined) {
      return newSss;
    }

    const diff = newSss - oldSss;
    return newSss + diff;
  };

  const handleAdjustForNewSSSRate = async () => {
    if (window.confirm("Are you sure to adjust for new SSS rate?")) {
      const mappedPayroll = payroll.payregister.map((item) => {
        let basicSalary = 0;
        if (
          item.basicSalary === undefined ||
          parseFloat(item.basicSalary) === 0
        ) {
          basicSalary = 100000;
          return {
            id: item.id,
            displayName: item.displayName,
            employeeNumber: item.employeeNumber || "N/A",
            lastName: item.lastName,
            firstName: item.firstName,
            basicSalary: 0,
            bankAccount: item.bankAccount || "N/A",
            halfMonth: 0,
            SSS: adjustForNewSss(basicSalary, item.id).toFixed(2),
            HDMF: handlePagibig(basicSalary).toFixed(2),
            Philhealth: handlePhilhealth(basicSalary).toFixed(2),
            thirteenMonth: handleThirteenMonth(basicSalary).toFixed(2),
            reimbursement: 0,
            withholdingTax: handleTax(0).toFixed(2),
            netPay: parseFloat(
              0 -
                adjustForNewSss(basicSalary, item.id) -
                handlePagibig(basicSalary) -
                handlePhilhealth(basicSalary) -
                handleTax(0)
            ).toFixed(2),
            commission: 0,
            incentives: 0,
            lwop: 0,
            salaryAdjustment: 0,
            overtime: 0,
            otherDeductions: 0,
            advances: 0,
            maxicare: 0,
            hdmf2: 0,
            hdmfloan: 0,
            sssloan: 0,
          };
        } else {
          basicSalary = item.basicSalary;
        }
        return {
          id: item.id,
          displayName: item.displayName,
          employeeNumber: item.employeeNumber || "N/A",
          lastName: item.lastName,
          firstName: item.firstName,
          basicSalary: basicSalary,
          bankAccount: item.bankAccount || "N/A",
          halfMonth: parseFloat(basicSalary / 2).toFixed(2),
          SSS: adjustForNewSss(basicSalary, item.id).toFixed(2),
          HDMF: handlePagibig(basicSalary).toFixed(2),
          Philhealth: handlePhilhealth(basicSalary).toFixed(2),
          thirteenMonth: handleThirteenMonth(basicSalary).toFixed(2),
          reimbursement: 0,
          withholdingTax: handleTax(basicSalary).toFixed(2),
          netPay: parseFloat(
            basicSalary / 2 -
              adjustForNewSss(basicSalary, item.id) -
              handlePagibig(basicSalary) -
              handlePhilhealth(basicSalary) -
              handleTax(basicSalary)
          ).toFixed(2),
          commission: 0,
          incentives: 0,
          lwop: 0,
          salaryAdjustment: 0,
          overtime: 0,
          otherDeductions: 0,
          advances: 0,
          maxicare: 0,
          hdmf2: 0,
          hdmfloan: 0,
          sssloan: 0,
        };
      });
      const data = {
        ...payroll,
        payregister: mappedPayroll,
      };
      await setDocData("payrollRuns", id, data);
      toast.success("Payroll adjusted for new SSS rate");
    }
  };

  const totalNet = payroll.payregister.reduce((acc, item) => {
    return acc + parseFloat(item.netPay);
  }, 0);

  const handleCopyPayroll = async () => {
    if (window.confirm("Are you sure to copy this payroll to a new date?")) {
      const newPayroll = {
        date: newDate,
        status: "draft",
        payregister: payroll.payregister,
      };
      await addNewDoc("payrollRuns", newPayroll);
      toast.success("Payroll copied to new date");
    }
  };

  const handleAdjustTaxes = async () => {
    if (window.confirm("are you sure?")) {
      const mappedPayroll = payroll.payregister.map((item) => {
        const newTax = handleTaxComputation(item);
        const newNetPay =
          totalGrossPay(item) -
          totalDeductions(item) +
          parseFloat(item.withholdingTax) -
          newTax;
        console.log(
          item.withholdingTax,
          newNetPay,
          totalGrossPay(item),
          totalDeductions(item)
        );
        return {
          ...item,
          withholdingTax: newTax.toFixed(2),
          netPay: newNetPay.toFixed(2),
        };
      });

      const data = {
        ...payroll,
        payregister: mappedPayroll,
      };
      await setDocData("payrollRuns", id, data);
    }
  };

  return (
    <div className="py-4 px-2 flex flex-col gap-4">
      <div>Draft Payroll for Payroll Run : {payroll.date}</div>
      {/* <button className="blue-button" onClick={handleAdjustTaxes}>
        Adjust Taxes
      </button> */}

      {/* <input
        className="w-36 "
        type="date"
        name="newDate"
        onChange={(e) => setNewDate(e.target.value)}
      />
      <button className="blue-button" onClick={handleCopyPayroll}>
        Copy to new payroll{" "}
      </button> */}

      <div>Payroll run date: {payroll.date}</div>
      <div>Total Net Pay: {totalNet.toLocaleString()}</div>
      <InputBox
        label="Search name"
        placeholder="Search name"
        onChange={handleSearch}
        type="search"
      />
      {selectedEmployee.length > 0 &&
        //check if there is admin access
        currentUser.roles.includes("admin") && (
          <div className="flex items-center gap-2">
            <Button label="Delete Item" onClick={handleDeleteItem} />
          </div>
        )}
      <div className="py-2 px-2 bg-white rounded-xl shadow-lg flex items-center justify-center">
        <PayrollDetails
          employees={filteredData}
          setSelectedEmployee={setSelectedEmployee}
        />
      </div>

      {selectedEmployee.length === 0 && (
        <div className="flex items-center gap-2">
          <Button
            label="Finalize and Submit for Approval"
            onClick={handleSubmit}
          />
          <WhiteButton label="Delete Draft" onClick={handleDeleteDraft} />
        </div>
      )}

      {selectedEmployee.length > 0 && (
        <div className="rounded-xl bg-white shadow-lg py-4 px-2">
          <Payslip
            payrollDetails={employeePayrollDetails}
            onChange={updatePayrollDetails}
            updatePayroll={updatePayroll}
            setEmployeePayrollDetails={setEmployeePayrollDetails}
            editDisabled={false}
            payrollId={id}
          />
        </div>
      )}

      {selectedEmployee < 1 ? (
        <div className="text-left text-xs">
          <DownloadExcel
            data={payroll.payregister}
            filename={`payroll-${payroll.date}`}
            label={"Download Payroll Register"}
          />
        </div>
      ) : null}
    </div>
  );
}

export default DraftPayroll;
